import jwt from 'jsonwebtoken';
import { store } from './store';
import { UserConstants } from '../constants';
import { ApiError } from './errors';
import fetchNetworkErrorWrap from './fetchNetworkErrorWrap';

const USERSTORE_DOMAIN = process.env.REACT_APP_USERSTORE_DOMAIN;
const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;
const TOKEN_TYPE = 'KEYCLOAK'; // process.env.REACT_APP_TOKEN_TYPE;
const KEYCLOAK_DEFAULT_TENANT = process.env.REACT_APP_KEYCLOAK_DEFAULT_TENANT;

const makeRequest = async <T = {}>(
  method: string,
  url: string,
  // Accept all types for body
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any,
  contentType = 'application/json',
  query?,
  options?,
  returnBlob?: boolean
): Promise<DTO.ApiResponse<T>> => {
  const { userAuth } = store.getState().auth;
  const tenant = localStorage.getItem('Tenant');
  const headerTenant = {
    'x-tenant-name': tenant || KEYCLOAK_DEFAULT_TENANT,
  };
  const headerContentType = {
    'Content-Type': contentType,
  };
  const headerAuth =
    userAuth && userAuth.id_token
      ? {
          Authorization: `Bearer ${userAuth.id_token}`,
        }
      : {};
  const requestHeaders = {
    ...headerContentType,
    ...headerAuth,
    ...headerTenant,
  };
  const decodedJwt =
    (userAuth && userAuth.id_token && jwt.decode(userAuth.id_token)) ||
    undefined;

  if (
    decodedJwt &&
    decodedJwt['exp'] &&
    decodedJwt['exp'] * 1000 < Date.now()
  ) {
    const state = store.getState();
    const refreshToken =
      state &&
      state.auth &&
      state.auth.userAuth &&
      state.auth.userAuth.refresh_token;

    let result: Response;
    let newIdToken: string;
    if (TOKEN_TYPE?.toUpperCase() === 'KEYCLOAK') {
      const refreshRequestOptions = {
        method: 'POST',
        headers: { ...headerContentType },
        body: JSON.stringify({ refresh_token: refreshToken }),
      };
      result = await fetch(
        `${EXCELENGINE_DOMAIN}/api/v1/userlogin/refreshtoken`,
        refreshRequestOptions
      );
      const payload = await result.json();
      const { data } = payload;
      newIdToken = data.id_token;
    } else {
      const refreshRequestOptions = {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({ refresh_token: refreshToken }),
        query,
        ...options,
      };
      result = await fetchNetworkErrorWrap(
        `${USERSTORE_DOMAIN}/users/refresh/`,
        refreshRequestOptions
      );
      const payload = await result.json();
      newIdToken = payload.id_token;
    }

    if (!newIdToken) {
      store.dispatch({ type: UserConstants.LOGOUT });

      throw new ApiError({
        error_code:
          'You have reached the maximum section time limit. Please login again.',
      });
    }

    store.dispatch({
      type: UserConstants.SET_ID_TOKEN,
      payload: {
        id_token: newIdToken,
      },
    });

    requestHeaders.Authorization = `Bearer ${newIdToken}`;
  }

  if (body) {
    if (body instanceof FormData) {
      delete requestHeaders['Content-Type'];
    } else {
      body = JSON.stringify(body);
    }
  }

  const requestOptions = {
    method,
    headers: requestHeaders,
    body,
    query,
    ...options,
  };

  const res = await fetchNetworkErrorWrap(url, requestOptions);
  const { status, headers } = res;

  if (
    returnBlob ||
    requestHeaders['Content-Type'] === 'application/octet-stream'
  ) {
    const blob = await res.blob();
    return {
      status,
      payload: { blob } as T & { blob: Blob },
      headers,
    };
  }

  const payload: T = await res.json();

  return {
    status,
    payload,
    headers,
  };
};

export default makeRequest;
