import jwt, { VerifyOptions } from 'jsonwebtoken';
import { UserConstants } from '../constants';
import makeRequest from './make-request';
import { getValidRoleAndGroup } from './get-valid-role-group';

const USERSTORE_DOMAIN = process.env.REACT_APP_USERSTORE_DOMAIN;
const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;

export const verifyToken = async (userAuth: { id_token: string }) => {
  let publicKey;
  let jwtVerifyOption: VerifyOptions;
  const TOKEN_TYPE = 'KEYCLOAK'; // process.env.REACT_APP_TOKEN_TYPE;
  if (TOKEN_TYPE?.toUpperCase() === 'KEYCLOAK') {
    jwtVerifyOption = {
      algorithms: ['RS256'],
      ignoreExpiration: false,
    };
    publicKey = await makeRequest<{ key: string }>(
      'GET',
      `${EXCELENGINE_DOMAIN}/api/v1/userlogin/apikey`
    ).then(({ payload }) => payload.key);
  } else {
    jwtVerifyOption = {
      algorithms: ['RS512'],
      ignoreExpiration: false,
    };
    publicKey = await makeRequest<{ key: string }>(
      'GET',
      `${USERSTORE_DOMAIN}/users/api/key`
    ).then(({ payload }) => payload.key);
  }

  try {
    const decodedJwt = jwt.verify(
      userAuth.id_token || '',
      publicKey,
      jwtVerifyOption
    ) as DTO.UserJWTData;
    return decodedJwt;
  } catch (err) {
    return null;
  }
};

export const isAdmin = (decodedJwt: DTO.UserJWTData) => {
  const role =
    decodedJwt &&
    decodedJwt.groups &&
    getValidRoleAndGroup(decodedJwt.groups).role;

  return !!role && role === UserConstants.ROLE_SUPERVISOR;
};

export const isSuperuser = (decodedJwt: DTO.UserJWTData) => {
  const TOKEN_TYPE = 'KEYCLOAK'; // process.env.REACT_APP_TOKEN_TYPE;
  if (TOKEN_TYPE?.toUpperCase() === 'KEYCLOAK') {
    return !!decodedJwt && decodedJwt.is_realm_admin;
  }
  return !!decodedJwt && decodedJwt.is_superuser;
};

export const getTenantName = (decodedJwt: DTO.UserJWTData) => {
  return decodedJwt && decodedJwt.realm;
};

export const getDisplayName = (decodedJwt: DTO.UserJWTData) => {
  const TOKEN_TYPE = 'KEYCLOAK'; // process.env.REACT_APP_TOKEN_TYPE;
  if (TOKEN_TYPE?.toUpperCase() === 'KEYCLOAK') {
    return (
      (decodedJwt &&
        (decodedJwt.name ||
          `${decodedJwt.given_name} ${decodedJwt.family_name}`)) ??
      ''
    );
  }
  return (
    (decodedJwt &&
      (decodedJwt.display_name ||
        `${decodedJwt.first_name} ${decodedJwt.last_name}`)) ??
    ''
  );
};
