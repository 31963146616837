import makeRequest from '../helpers/make-request';

const USERSTORE_DOMAIN = process.env.REACT_APP_USERSTORE_DOMAIN;
const EXCELENGINE_DOMAIN = process.env.REACT_APP_EXCELENGINE_DOMAIN;
const KEYCLOAK_LOGOUT_URL = process.env.REACT_APP_KEYCLOAK_LOGOUT;
const KEYCLOAK_DEFAULT_TENANT = process.env.REACT_APP_KEYCLOAK_DEFAULT_TENANT;

const login = (username: string, password: string) => {
  const url = `${USERSTORE_DOMAIN}/users/auth/login/`;
  const body = { username, password };

  return makeRequest<{
    id_token: string;
    refresh_token: string;
  }>('POST', url, body);
};

const passwordReset = (email: string) => {
  const url = `${USERSTORE_DOMAIN}/users/api/password/reset`;
  const body = { email };

  return makeRequest('POST', url, body);
};

const passwordResetCodeResend = (email: string) => {
  const url = `${USERSTORE_DOMAIN}/users/api/password/reset/confirm`;
  const body = { email };

  return makeRequest('POST', url, body);
};

const passwordResetConfirm = (
  email: string,
  code: string,
  password: string
) => {
  const url = `${USERSTORE_DOMAIN}/users/api/password/reset/confirm`;
  const body = {
    email,
    code,
    new_password: password,
  };

  return makeRequest('PUT', url, body);
};

const createAnonymousUser = (anonymousUser: DTO.AnonymousUser) => {
  const url = `${USERSTORE_DOMAIN}/acl/api/anonusers`;
  return makeRequest<{
    user_id: string;
  }>('POST', url, anonymousUser);
};

const createKeyCloakAnonymousUser = (
  anonymousUser: DTO.KeyCloakAnonymousUser
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/keycloak/CreateAnonymousUser`;
  return makeRequest<{
    data: string;
  }>('POST', url, anonymousUser);
};

const sendOTPToAnonymousUser = (anonymousUserId: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/keycloak/SendOTPToAnonymousUser/${anonymousUserId}`;
  return makeRequest('POST', url);
};

const generateAnonymouseUserToken = (
  anonymousUserId: string,
  linkId: string,
  code: string,
  client: string
) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/keycloak/GenerateAnonymouseUserToken/${anonymousUserId}/${linkId}/${code}/${client}`;
  return makeRequest<{ data: { targetUrl: string; access_token: string } }>(
    'POST',
    url
  );
};

const generateAnonymousLink = (anonymousLink: DTO.AnonymousLink) => {
  const url = `${USERSTORE_DOMAIN}/acl/api/links`;
  return makeRequest<{
    link_id: string;
    link_url: string;
  }>('POST', url, anonymousLink);
};

const getKeyCloakAuth = (code: string, redirectUrl: string) => {
  const url = `${EXCELENGINE_DOMAIN}/api/v1/userlogin/GenerateToken?code=${code}&redirectUrl=${redirectUrl}`;
  return makeRequest<{
    data: {
      access_token: string;
      refresh_token: string;
      username: string;
    };
  }>('GET', url);
};

const getLogoutUrl = (returnUrl?: string) => {
  if (KEYCLOAK_LOGOUT_URL) {
    let keyCloakLogOutUrl = KEYCLOAK_LOGOUT_URL;
    const tenant = localStorage.getItem('Tenant');
    if (tenant) {
      keyCloakLogOutUrl = keyCloakLogOutUrl?.replace('{tenant}', tenant);
    } else {
      keyCloakLogOutUrl = keyCloakLogOutUrl?.replace(
        '{tenant}',
        KEYCLOAK_DEFAULT_TENANT ?? ''
      );
    }

    return `${keyCloakLogOutUrl}${window.location.origin}?${
      tenant ? `tenant=${tenant}&` : ''
    }return_url=${encodeURIComponent(
      `${returnUrl ||
        window.location.href
          .replace(window.location.origin, '')
          .replace(/ /g, '@!@')}`
    )}`;
  }
  return undefined;
};

export const UserService = {
  login,
  passwordReset,
  passwordResetCodeResend,
  passwordResetConfirm,
  createAnonymousUser,
  generateAnonymousLink,
  getKeyCloakAuth,
  getLogoutUrl,
  createKeyCloakAnonymousUser,
  sendOTPToAnonymousUser,
  generateAnonymouseUserToken,
};
