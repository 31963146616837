import { ProductsRoadMapThunkAction } from './types/productRoadmap.actions.types';
import { ProductRoadMapConstants } from '../constants';
import { ProductService } from '../services';
import { ApiError } from '../helpers';
import { AlertActions } from './alert.actions';

const getProducts = (
  data: DTO.GetProductsRequest
): ProductsRoadMapThunkAction => async dispatch => {
  dispatch({
    type: ProductRoadMapConstants.GET_ROADMAP_PRODUCTS_REQUEST,
    payload: {},
  });
  try {
    const { status, payload } = await ProductService.getProducts(data);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductRoadMapConstants.GET_ROADMAP_PRODUCTS_SUCCESS,
      payload: {
        products: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: ProductRoadMapConstants.GET_ROADMAP_PRODUCTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const updateProduct = (
  productId: string,
  product: DTO.UpdateProductRequest
): ProductsRoadMapThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductRoadMapConstants.UPDATE_PRODUCT_LAUNCH_DATE_REQUEST,
      payload: {
        productId,
        launchDate: product.launchDate,
        startDate: product.startDate,
      },
    });

    const { status, payload } = await ProductService.updateProduct(
      productId,
      product
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductRoadMapConstants.UPDATE_PRODUCT_LAUNCH_DATE_SUCCESS,
      payload: {
        productId,
        launchDate: product.launchDate,
        startDate: product.startDate,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductRoadMapConstants.UPDATE_PRODUCT_LAUNCH_DATE_FAILURE,
      payload: { error: msg },
    });
  }
};

export const ProductRoadMapAction = {
  getProducts,
  updateProduct,
};
