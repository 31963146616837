import { UserConstants } from '../constants';
import { UserAction } from '../actions/types';

export const initialAuthState: STATES.AuthState = {
  loggingIn: false,
  isLoggingOut: false,
  loggedIn: false,
  userAuth: null,
  isAdmin: false,
  groups: [],
  remember: true,
  userId: '',
  isSuperuser: false,
  loginFailed: null,
  logOutUrl: '',
  tenant: null,
};

export const auth = (
  state = initialAuthState,
  action: UserAction
): STATES.AuthState => {
  switch (action.type) {
    case UserConstants.LOGIN_REQUEST: {
      return {
        ...state,
        loginFailed: null,
        loggingIn: true,
        remember: action.payload.remember,
      };
    }
    case UserConstants.LOGIN_SUCCESS: {
      const {
        userAuth,
        isAdmin,
        userId,
        isSuperuser,
        groups,
        tenant,
      } = action.payload;

      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        groups,
        userAuth,
        isAdmin,
        userId,
        isSuperuser,
        tenant,
      };
    }
    case UserConstants.LOGIN_FAILURE: {
      const { reason } = action.payload;

      return {
        ...state,
        loginFailed: reason,
        loggingIn: false,
      };
    }
    case UserConstants.LOGOUT: {
      return {
        ...initialAuthState,
        isLoggingOut: true,
      };
    }
    case UserConstants.KYECLOAK_LOGOUT: {
      const { logOutUrl } = action.payload;
      return {
        ...initialAuthState,
        isLoggingOut: true,
        logOutUrl,
      };
    }
    case UserConstants.SET_ID_TOKEN: {
      const { id_token } = action.payload;

      return {
        ...state,
        userAuth: state.userAuth ? { ...state.userAuth, id_token } : null,
      };
    }
    case UserConstants.SET_ANONYMOUS_ID_TOKEN: {
      const { id_token } = action.payload;

      return {
        ...state,
        userAuth: {
          username: '',
          id_token,
          refresh_token: '',
        },
      };
    }
    case UserConstants.LOGIN_CLEAN: {
      return {
        ...state,
        loginFailed: null,
      };
    }
    case UserConstants.LOGGED_OUT: {
      return { ...initialAuthState };
    }
    default:
      return state;
  }
};
