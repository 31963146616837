import { UserConstants } from '../constants';
import { UserAction, UserThunkAction } from './types';
import { FileManagerService, UserService } from '../services';
import { ApiError, getValidRoleAndGroup } from '../helpers';
import { AlertActions } from './alert.actions';
import {
  getDisplayName,
  isAdmin,
  isSuperuser,
  verifyToken,
  getTenantName,
} from '../helpers/user-auth';

const resetPassword = (
  email: string,
  resend?: boolean
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.PASSWORD_RESET_REQUEST,
      payload: { email },
    });

    const request = resend
      ? UserService.passwordResetCodeResend(email)
      : UserService.passwordReset(email);

    const { payload } = await request;

    if (!payload || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({ type: UserConstants.PASSWORD_RESET_REQUEST_SUCCESS });

    if (resend) {
      dispatch(AlertActions.success('UserManagerUserForm.verify.email.send'));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.PASSWORD_RESET_REQUEST_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetPasswordConfirm = (
  email: string,
  code: string,
  password: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.PASSWORD_RESET_CONFIRM,
    });

    const { payload } = await UserService.passwordResetConfirm(
      email,
      code,
      password
    );

    if (!payload || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({ type: UserConstants.PASSWORD_RESET_CONFIRM_SUCCESS });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.PASSWORD_RESET_CONFIRM_FAILURE,
      payload: { error: msg },
    });
  }
};

const login = (
  username: string,
  password: string,
  remember: boolean
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.LOGIN_REQUEST, payload: { remember } });

    const { payload } = await UserService.login(username, password);

    if (payload.status !== 'OK') {
      switch (payload.error_code) {
        case 'ACCOUNT_LOCKED':
        case 'PASSWORD_RESET_REQUIRED': {
          dispatch({ type: UserConstants.LOGIN_CHANGE_PASSWORD_REQUIRED });
          window.location.href = '/forgot/password';
          return;
        }
        case 'PASSWORD_EXPIRED': {
          dispatch({
            type: UserConstants.LOGIN_FAILURE,
            payload: { reason: 'PasswordExpired' },
          });
          return;
        }
        default: {
          throw new ApiError(payload);
        }
      }
    }

    const decodedJwt = await verifyToken(payload);

    if (
      decodedJwt &&
      decodedJwt.groups &&
      getValidRoleAndGroup(decodedJwt.groups).group
    ) {
      dispatch({
        type: UserConstants.LOGIN_SUCCESS,
        payload: {
          groups: decodedJwt.groups,
          userAuth: {
            id_token: payload.id_token,
            refresh_token: payload.refresh_token,
            username,
          },
          userInfo: {
            displayName: getDisplayName(decodedJwt),
            userEmailID: username,
          },
          userId: decodedJwt.sub,
          isAdmin: isAdmin(decodedJwt),
          isSuperuser: isSuperuser(decodedJwt),
          tenant: getTenantName(decodedJwt),
        },
      });

      return;
    }

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'UnauthorizedUserGroup' },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const getKeyCloakAuth = (
  code: string,
  redirectUrl: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.LOGIN_REQUEST,
      payload: { remember: true },
    });

    const { payload } = await UserService.getKeyCloakAuth(code, redirectUrl);

    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    const decodedJwt = await verifyToken({
      id_token: data.access_token,
    });

    if (
      decodedJwt &&
      decodedJwt.groups &&
      getValidRoleAndGroup(decodedJwt.groups).group
    ) {
      dispatch({
        type: UserConstants.LOGIN_SUCCESS,
        payload: {
          groups: decodedJwt.groups,
          userAuth: {
            id_token: data.access_token,
            refresh_token: data.refresh_token,
            username: decodedJwt.preferred_username,
          },
          userInfo: {
            displayName: getDisplayName(decodedJwt),
            userEmailID: decodedJwt.preferred_username,
          },
          userId: decodedJwt.sub,
          isAdmin: isAdmin(decodedJwt),
          isSuperuser: isSuperuser(decodedJwt),
          tenant: getTenantName(decodedJwt),
        },
      });

      return;
    }

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'UnauthorizedUserGroup' },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const validateTokens = (
  authToken: string,
  refreshToken: string,
  username: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.LOGIN_REQUEST,
      payload: { remember: false },
    });

    const decodedJwt = await verifyToken({ id_token: authToken });

    if (
      decodedJwt &&
      decodedJwt.groups &&
      getValidRoleAndGroup(decodedJwt.groups).group
    ) {
      dispatch({
        type: UserConstants.LOGIN_SUCCESS,
        payload: {
          groups: decodedJwt.groups,
          userAuth: {
            id_token: authToken,
            refresh_token: refreshToken,
            username,
          },
          userInfo: {
            displayName: getDisplayName(decodedJwt),
            userEmailID: username,
          },
          userId: decodedJwt.sub,
          isAdmin: isAdmin(decodedJwt),
          isSuperuser: isSuperuser(decodedJwt),
          tenant: getTenantName(decodedJwt),
        },
      });

      return;
    }

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'UnauthorizedUserGroup' },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const createAnonymousUser = (
  anonymousUser: DTO.AnonymousUser
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.createAnonymousUser(anonymousUser);

    if (!payload || payload.status !== 'OK') {
      if (payload.error_code === 'INVALID_INPUT') {
        throw new ApiError({
          error_code: 'INVALID_INPUT_ANONYMOUSUSER',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_USER_SUCCESS,
      payload: {
        userId: payload.user_id,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const generateAnonymousLink = (
  anonymousLink: DTO.AnonymousLink
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.generateAnonymousLink(anonymousLink);

    if (!payload || payload.status !== 'OK') {
      switch (payload.error_code) {
        case 'PERMISSION_DENIED': {
          throw new ApiError({
            error_code: 'PERMISSION_DENIED_ANONYMOUSLINK',
          });
        }
        case 'USER_NOT_FOUND': {
          throw new ApiError({
            error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
          });
        }
        default: {
          throw new ApiError(payload);
        }
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_LINK_SUCCESS,
      payload: {
        linkUrl: payload.link_url,
        linkId: payload.link_id,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const createKeyCloakAnonymousUser = (
  anonymousUser: DTO.KeyCloakAnonymousUser
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.createKeyCloakAnonymousUser(
      anonymousUser
    );

    if (!payload || payload.status !== 'Success') {
      if (
        payload?.errorCode === 'INVALID_INPUT' ||
        payload?.errorCode === 'MISSING_KEY_IN_BODY' ||
        payload?.errorCode === 'INVALID_DATETIME'
      ) {
        throw new ApiError({
          error_code: 'INVALID_INPUT_ANONYMOUSUSER',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_USER_SUCCESS,
      payload: {
        userId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const createLinkResource = (
  linkResource: DTO.LinkResourceDetail
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await FileManagerService.createLinkResource(
      linkResource
    );

    if (!payload || payload.status !== 'Success') {
      switch (payload.error_code) {
        case 'PERMISSION_DENIED': {
          throw new ApiError({
            error_code: 'PERMISSION_DENIED_ANONYMOUSLINK',
          });
        }
        case 'USER_NOT_FOUND': {
          throw new ApiError({
            error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
          });
        }
        default: {
          throw new ApiError(payload);
        }
      }
    }

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_LINK_SUCCESS,
      payload: {
        linkUrl: '',
        linkId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const sendOtpToAnonymousKeyCloakUser = (
  anonymousUserId: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.sendOTPToAnonymousUser(
      anonymousUserId
    );

    if (!payload || payload.status !== 'Success') {
      if (payload?.errorCode === 'USER_NOT_FOUND') {
        throw new ApiError({
          error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.SEND_KEYCLOAK_OTP_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const generateAnonymousKeyCloakUserToken = (
  anonymousUserId: string,
  linkId: string,
  code: string,
  client: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.CREATE_ANONYMOUS_REQUEST });

    const { payload } = await UserService.generateAnonymouseUserToken(
      anonymousUserId,
      linkId,
      code,
      client
    );

    if (!payload || payload.status !== 'Success') {
      if (payload?.errorCode === 'MISSING_PARAMETERS') {
        throw new ApiError({
          error_code: 'INVALID_INPUT_ANONYMOUSUSER',
        });
      } else if (payload?.errorCode === 'USER_NOT_FOUND') {
        throw new ApiError({
          error_code: 'USER_NOT_FOUND_ANONYMOUSLINK',
        });
      } else {
        throw new ApiError(payload);
      }
    }

    dispatch({
      type: UserConstants.GENERATE_KEYCLOAK_ANONYMOUS_TOKEN_SUCCESS,
      payload: {
        redirectUrl: payload.data.targetUrl,
        anonymousToken: payload.data.access_token,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.CREATE_ANONYMOUS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const logout = (returnUrl?: string): UserAction => {
  const logOutUrl = UserService.getLogoutUrl(returnUrl);
  if (logOutUrl) {
    return { type: UserConstants.KYECLOAK_LOGOUT, payload: { logOutUrl } };
  }
  return { type: UserConstants.LOGOUT };
};

const loggedOut = (): UserAction => {
  return { type: UserConstants.LOGGED_OUT };
};

const resetPasswordClean = (): UserAction => {
  return { type: UserConstants.PASSWORD_RESET_CLEAN };
};

const loginClean = (): UserAction => {
  return { type: UserConstants.LOGIN_CLEAN };
};

const setIdToken = (id_token: string): UserAction => ({
  type: UserConstants.SET_ID_TOKEN,
  payload: {
    id_token,
  },
});

const setAnonymousIdToken = (token: string): UserAction => ({
  type: UserConstants.SET_ANONYMOUS_ID_TOKEN,
  payload: {
    id_token: token,
  },
});

export const UserActions = {
  login,
  resetPassword,
  resetPasswordConfirm,
  logout,
  loggedOut,
  setIdToken,
  resetPasswordClean,
  loginClean,
  createAnonymousUser,
  generateAnonymousLink,
  setAnonymousIdToken,
  validateTokens,
  getKeyCloakAuth,
  createKeyCloakAnonymousUser,
  sendOtpToAnonymousKeyCloakUser,
  generateAnonymousKeyCloakUserToken,
  createLinkResource,
};
