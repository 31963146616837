import {
  EngineAction,
  UserAction,
  MasterReferenceAction,
  EngineChainAction,
} from '../actions/types';
import {
  EngineConstants,
  UserConstants,
  MasterReferenceConstants,
} from '../constants';
import { arrayRemoveFirst, arrayReplaceFirst } from '../helpers';

export const initialState: STATES.ProductEnginesState = {
  isLoading: false,
  isExecuting: false,
  engines: [],
  page: 1,
  totalPages: 0,
  total: 0,
  pageSize: 3,
  searchText: '',
  refreshTrigger: 0,
  selectedEngineName: null,
  selectedEngine: null,
  selectedRevision: null,
  isLoadingDetails: false,
  isUpdating: false,
  updateSuccess: false,
  sortBy: 'updated',
  generatingTestCases: false,
  generatedTestCasesFile: null,
  generatedTestCasesFileName: null,
  generatedTestData: null,
  propertiesChanges: null,
  selectedVersionId: null,
  chainedEngines: [],
  unChainedEngines: [],
  showWarningMessage: false,
};

export const productEngines = (
  state = initialState,
  action: EngineAction | UserAction | MasterReferenceAction | EngineChainAction
): STATES.ProductEnginesState => {
  switch (action.type) {
    case EngineConstants.GET_PRODUCT_ENGINES_REQUEST: {
      const { page, searchText, sortBy, pageSize } = action.payload;

      return {
        ...state,
        isLoading: true,
        page,
        searchText,
        sortBy,
        pageSize,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINES_SUCCESS: {
      const { engines, page, searchText, total, sortBy } = action.payload;

      if (
        searchText !== state.searchText ||
        page !== state.page ||
        sortBy !== state.sortBy
      ) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        engines: page === 1 ? engines : [...state.engines, ...engines],
        totalPages:
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0),
        total,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINE_DETAILS_REQUEST: {
      const { serviceName, revision, versionId } = action.payload;

      return {
        ...state,
        selectedEngineName: serviceName,
        selectedVersionId: versionId,
        selectedRevision: revision,
        isLoadingDetails: true,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS: {
      const { engine, serviceName, versionId } = action.payload;

      if (
        state.selectedEngineName !== serviceName ||
        state.selectedVersionId !== versionId
      ) {
        return state;
      }

      return {
        ...state,
        generatingTestCases: false,
        generatedTestCasesFile: null,
        selectedEngine: engine,
        isLoadingDetails: false,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINE_DETAILS_FAILURE: {
      const { serviceName, versionId } = action.payload;

      if (
        state.selectedEngineName !== serviceName ||
        state.selectedVersionId !== versionId
      ) {
        return state;
      }

      return {
        ...state,
        isLoadingDetails: false,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_INPUTS: {
      return {
        ...state,
        isLoadingDetails: true,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_INPUTS_SUCCESS: {
      const { xlInputs } = action.payload;
      if (state.selectedEngine) {
        const tempSelectedEngine = {
          ...state.selectedEngine,
          xlInputs,
        };
        return {
          ...state,
          selectedEngine: tempSelectedEngine,
          isLoadingDetails: false,
        };
      }
      return {
        ...state,
        isLoadingDetails: false,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE: {
      return {
        ...state,
        isLoadingDetails: false,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_REQUEST: {
      return {
        ...state,
        updateSuccess: false,
        isUpdating: true,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_SUCCESS: {
      const { serviceName } = action.payload;
      if (serviceName !== state.selectedEngineName) {
        return state;
      }

      return {
        ...state,
        updateSuccess: true,
        isUpdating: false,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case EngineConstants.PUBLISH_UPLOADED_ENGINE_SUCCESS: {
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case EngineConstants.GENERATE_TEST_CASES_REQUEST: {
      return {
        ...state,
        generatingTestCases: true,
      };
    }
    case EngineConstants.GENERATE_TEST_CASES_SUCCESS: {
      const { url, fileName, testData } = action.payload;

      return {
        ...state,
        generatingTestCases: false,
        generatedTestCasesFile: url,
        generatedTestCasesFileName: fileName,
        generatedTestData: testData,
      };
    }
    case EngineConstants.GENERATE_TEST_CASES_FAILURE: {
      return {
        ...state,
        generatingTestCases: false,
      };
    }
    case EngineConstants.ADD_ENGINE_SUCCESS: {
      const { noPublish } = action.payload;

      if (noPublish) {
        return state;
      }

      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case EngineConstants.UPDATE_ENGINE_FAVORITE_REQUEST: {
      const { serviceName, favorite } = action.payload;

      return {
        ...state,
        engines: arrayReplaceFirst(
          state.engines,
          engine => engine.serviceName === serviceName,
          engine => ({
            ...engine,
            isStarred: favorite,
          })
        ),
      };
    }

    case EngineConstants.DELETE_ENGINE_SUCCESS: {
      const { serviceName } = action.payload;

      return {
        ...state,
        engines: arrayRemoveFirst(
          state.engines,
          engine => engine.serviceName === serviceName
        ),
        refreshTrigger: state.refreshTrigger + 1,
      };
    }

    case EngineConstants.SHOW_UPDATE_CONFIRM: {
      const { data } = action.payload;
      return {
        ...state,
        propertiesChanges: data,
      };
    }

    case EngineConstants.UPDATE_ENGINE_PROPS: {
      const { data } = action.payload;
      return {
        ...state,
        propertiesChanges: data,
      };
    }

    case MasterReferenceConstants.MASTER_REF_SYNC_SUCCESS: {
      return {
        ...state,
        masterReferenceSource: '',
      };
    }

    case EngineConstants.CANCEL_UPDATE_CONFIRM: {
      return {
        ...state,
        propertiesChanges: null,
      };
    }

    case EngineConstants.PRODUCT_ENGINES_RESET:
    case UserConstants.KYECLOAK_LOGOUT:
    case UserConstants.LOGOUT: {
      return { ...initialState };
    }

    case EngineConstants.GET_ENGINE_VERSIONS_SUCCESS: {
      const { serviceName, versions } = action.payload;

      return {
        ...state,
        engines: arrayReplaceFirst(
          state.engines,
          engine => engine.serviceName === serviceName,
          engine => ({ ...engine, versions })
        ),
      };
    }

    case EngineConstants.GET_ENGINE_CHAIN_REQUEST: {
      return {
        ...state,
        isExecuting: true,
        chainedEngines: [],
        showWarningMessage: false,
      };
    }
    case EngineConstants.CREATE_ENGINE_CHAIN_REQUEST:
    case EngineConstants.DELETE_ENGINE_CHAIN_REQUEST:
    case EngineConstants.GET_ENGINE_UNCHAIN_CHAIN_REQUEST: {
      return {
        ...state,
        isExecuting: true,
        showWarningMessage: false,
      };
    }

    case EngineConstants.RESTORE_VERSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        showWarningMessage: false,
      };
    }

    case EngineConstants.RESTORE_VERSION_SUCCESS: {
      const { latestVersionId, selectedEngineName } = action.payload;
      return {
        ...state,
        latestVersionId,
        selectedEngineName,
        isLoading: false,
      };
    }

    case EngineConstants.DELETE_ENGINE_CHAIN_SUCCESS:
    case EngineConstants.GET_ENGINE_CHAIN_FAILURE:
    case EngineConstants.CREATE_ENGINE_CHAIN_FAILURE:
    case EngineConstants.GET_ENGINE_UNCHAIN_CHAIN_FAILURE:
    case EngineConstants.CREATE_ENGINE_CHAIN_SUCCESS:
    case EngineConstants.DELETE_ENGINE_CHAIN_FAILURE: {
      return {
        ...state,
        isExecuting: false,
      };
    }

    case EngineConstants.RESTORE_VERSION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case EngineConstants.GET_ENGINE_CHAIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isExecuting: false,
        chainedEngines: data,
      };
    }

    case EngineConstants.GET_ENGINE_UNCHAIN_CHAIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isExecuting: false,
        unChainedEngines: data,
      };
    }

    case EngineConstants.ENGINE_CHAIN_MISMATCH_ERROR: {
      return {
        ...state,
        showWarningMessage: !state.showWarningMessage,
        isExecuting: false,
      };
    }

    default:
      return state;
  }
};
