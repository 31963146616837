import moment from 'moment-timezone';
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import useUserpilot from '../hooks/useUserpilot';
import { AnonymousRoute, PrivateRoute } from '../components/Routes';
import Error from './Error';

const MainThemeProvider = lazy(() =>
  import(
    /* webpackChunkName: 'MainThemeProvider' */ '../providers/MainThemeProvider'
  )
);
const FormBuilderThemeProvider = lazy(() =>
  import(
    /* webpackChunkName: 'FormBuilderThemeProvider' */ '../providers/FormBuilderThemeProvider'
  )
);
const Auth = lazy(() => import(/* webpackChunkName: 'Auth' */ './Auth'));
const Main = lazy(() => import(/* webpackChunkName: 'Main' */ './Main'));
const ProductTutorialCalculator = lazy(() =>
  import(
    /* webpackChunkName: 'ProductTutorialCalculator' */ './ProductTutorialCalculator'
  )
);

const Admin = lazy(() => import(/* webpackChunkName: 'Admin' */ './Admin'));

/* Set default timezone as UTC */
moment.tz.setDefault('GMT');

const mapStateToAppProps = ({
  auth: { loggedIn, isLoggingOut },
  language: { intl },
}: STATES.AppState) => ({
  loggedIn,
  intl,
  isLoggingOut,
});

const App = () => {
  useUserpilot();
  const { loggedIn, isLoggingOut } = useSelector(mapStateToAppProps);

  const renderTutorialCalc = () => (
    <FormBuilderThemeProvider>
      <ProductTutorialCalculator />
    </FormBuilderThemeProvider>
  );

  return (
    <Switch>
      <PrivateRoute
        loggedIn={loggedIn}
        isLoggingOut={isLoggingOut}
        path="/admin"
        render={() => (
          <MainThemeProvider>
            <Admin />
          </MainThemeProvider>
        )}
      />

      <PrivateRoute
        loggedIn={loggedIn}
        isLoggingOut={isLoggingOut}
        path="/error"
        render={() => (
          <MainThemeProvider>
            <Error />
          </MainThemeProvider>
        )}
      />

      <PrivateRoute
        loggedIn={loggedIn}
        isLoggingOut={isLoggingOut}
        path="(/shared)?/products/:productId/tutorial/:serviceName/calculator/:section?"
        render={renderTutorialCalc}
      />
      <AnonymousRoute
        loggedIn={loggedIn}
        path="(/shared)?/products/:productId/tutorial/:serviceName/anonymous/calculator/:section?"
        render={renderTutorialCalc}
      />
      <AnonymousRoute
        loggedIn={loggedIn}
        path="/login"
        render={() => (
          <MainThemeProvider>
            <Auth />
          </MainThemeProvider>
        )}
      />
      <AnonymousRoute
        loggedIn={loggedIn}
        path="/forgot/password"
        render={() => (
          <MainThemeProvider>
            <Auth />
          </MainThemeProvider>
        )}
      />
      <AnonymousRoute
        loggedIn={loggedIn}
        path="/reset/password"
        render={() => (
          <MainThemeProvider>
            <Auth />
          </MainThemeProvider>
        )}
      />
      <AnonymousRoute
        loggedIn={loggedIn}
        path="/validateToken"
        render={() => (
          <MainThemeProvider>
            <Auth />
          </MainThemeProvider>
        )}
      />
      <AnonymousRoute
        loggedIn={loggedIn}
        path="/anonymous/validate"
        render={() => (
          <MainThemeProvider>
            <Auth />
          </MainThemeProvider>
        )}
      />
      <PrivateRoute
        loggedIn={loggedIn}
        isLoggingOut={isLoggingOut}
        path="/"
        render={() => (
          <MainThemeProvider>
            <Main />
          </MainThemeProvider>
        )}
      />
    </Switch>
  );
};

export default App;
