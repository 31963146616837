import { UserConstants } from '../constants';
import { UserAction } from '../actions/types';

export const initialState: STATES.ResetPasswordState = {
  requestingResetCode: false,
  requestedResetCode: false,
  confirmingResetCode: false,
  confirmedResetCode: false,
  email: null,
};

export const resetPassword = (
  state = initialState,
  action: UserAction
): STATES.ResetPasswordState => {
  switch (action.type) {
    case UserConstants.PASSWORD_RESET_REQUEST: {
      const { email } = action.payload;

      return {
        ...initialState,
        requestedResetCode: false,
        requestingResetCode: true,
        email,
      };
    }
    case UserConstants.PASSWORD_RESET_REQUEST_SUCCESS: {
      return {
        ...state,
        requestedResetCode: true,
        requestingResetCode: false,
      };
    }
    case UserConstants.PASSWORD_RESET_REQUEST_FAILURE: {
      return {
        ...initialState,
      };
    }
    case UserConstants.PASSWORD_RESET_CONFIRM: {
      return {
        ...state,
        confirmingResetCode: true,
        confirmedResetCode: false,
      };
    }
    case UserConstants.PASSWORD_RESET_CONFIRM_SUCCESS: {
      return {
        ...state,
        confirmingResetCode: false,
        confirmedResetCode: true,
      };
    }
    case UserConstants.PASSWORD_RESET_CONFIRM_FAILURE: {
      return {
        ...state,
        confirmingResetCode: false,
        confirmedResetCode: false,
      };
    }
    case UserConstants.PASSWORD_RESET_CLEAN: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
