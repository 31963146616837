import { MasterReferenceConstants } from '../constants';
import { ApiError } from '../helpers';
import { MasterReferenceServices } from '../services';
import { AlertActions } from './alert.actions';
import { MasterReferenceAction, MasterReferenceThunkAction } from './types';

const getMasterAPIDefinition = (): MasterReferenceThunkAction => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.GET_MASTER_API_REQUEST,
    });

    const {
      payload,
      status,
    } = await MasterReferenceServices.getMasterAPIdefFile();

    if (status !== 200 || payload.status === 'Error') {
      if (
        payload.status === 'Error' &&
        payload.errorCode === 'MASTER_API_DEFINITION_NOT_EXISTS'
      ) {
        dispatch({
          type: MasterReferenceConstants.GET_MASTER_API_SUCCESS,
          payload: { file: null },
        });

        return;
      }

      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_API_SUCCESS,
      payload: { file: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_API_FAILURE,
      payload: { error: msg },
    });
  }
};

const getMasterReferences = (
  docRequest: DTO.GetMasterReferencesRequest
): MasterReferenceThunkAction => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REFS_REQUEST,
      payload: { docRequest },
    });

    const {
      payload,
      status,
    } = await MasterReferenceServices.getMasterReferences(docRequest);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REFS_SUCCESS,
      payload: {
        documents: payload.data,
        total: payload.count,
        docRequest,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REFS_FAILURE,
      payload: { error: msg },
    });
  }
};

const upload = (
  file: File | undefined,
  documentId: string | null | undefined,
  category: 'api' | 'normal',
  request: DTO.UpdateMasterRefRequest
): MasterReferenceThunkAction<boolean> => async (dispatch, getState) => {
  try {
    dispatch({
      type: MasterReferenceConstants.MASTER_REF_UPLOAD_START,
    });
    let response;
    if (category === 'normal') {
      response = await MasterReferenceServices.upload(
        file,
        documentId,
        request,
        uploadProgress => {
          if (uploadProgress === 100) {
            return;
          }

          // 5% for server processing time
          dispatch({
            type: MasterReferenceConstants.MASTER_REF_UPLOAD_PROGRESS,
            payload: {
              uploadProgress: Math.max(5, uploadProgress - 5),
            },
          });
        },
        xhrRef => {
          dispatch({
            type: MasterReferenceConstants.MASTER_REF_UPLOAD_XHR_REF,
            payload: { xhrRef },
          });
        }
      );
    } else {
      response = await MasterReferenceServices.uploadMasterAPIDef(
        file,
        documentId,
        uploadProgress => {
          if (uploadProgress === 100) {
            return;
          }

          // 5% for server processing time
          dispatch({
            type: MasterReferenceConstants.MASTER_REF_UPLOAD_PROGRESS,
            payload: {
              uploadProgress: Math.max(5, uploadProgress - 5),
            },
          });
        },
        xhrRef => {
          dispatch({
            type: MasterReferenceConstants.MASTER_REF_UPLOAD_XHR_REF,
            payload: { xhrRef },
          });
        }
      );
    }
    const { status, payload } = response;
    if (status !== 200 || payload.status !== 'Success') {
      if (
        payload.errorCode &&
        payload.Data &&
        ['INVALID_ENGINE_CONFIGURATION', 'REFERENCE_RANGE_ERROR'].includes(
          payload.errorCode
        )
      ) {
        const { Error, CorrelationId } = payload.Data;
        const {
          language: { intl },
        } = getState();

        dispatch({
          type: MasterReferenceConstants.MASTER_REF_UPLOAD_ERROR,
          payload: {
            error: [
              ...Error.map(({ Key, Value }) =>
                intl.formatMessage({ id: Key }, { values: Value.join(', ') })
              ),
              `(${CorrelationId})`,
            ].join('<br />'),
          },
        });

        return false;
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_UPLOAD_SUCCESS,
      payload: {
        engineReferences: [],
        fileName: file?.name ?? '',
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_UPLOAD_ERROR,
      payload: { error: msg },
    });

    return false;
  }
};

const downloadReferenceFile = (
  documentId: string
): MasterReferenceThunkAction => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  window.location.href = MasterReferenceServices.getDownloadReferenceFileUrl(
    documentId,
    id_token
  );
};

const downloadAPIDefFile = (documentId: string): MasterReferenceThunkAction => (
  _,
  getState
) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return;
  }

  const { id_token } = userAuth;

  window.location.href = MasterReferenceServices.getDownloadMasterAPIDefFileUrl(
    documentId,
    id_token
  );
};

const deleteDocument = (
  document: DTO.MasterReferenceDetail
): MasterReferenceThunkAction => async dispatch => {
  try {
    const { id, serviceName } = document;
    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_REQUEST,
      payload: {
        id,
        name: serviceName,
      },
    });

    const { status, payload } = await MasterReferenceServices.deleteDocument(
      id,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_SUCCESS,
      payload: {
        id,
        name: serviceName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_FAILURE,
      payload: { error: msg },
    });
  }
};

const deleteAPIDefDocument = (
  id: string
): MasterReferenceThunkAction => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_REQUEST,
      payload: {
        id,
      },
    });

    const {
      status,
      payload,
    } = await MasterReferenceServices.deleteMasterAPIDef(id);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_SUCCESS,
      payload: {
        id,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.DELETE_MASTER_REF_FAILURE,
      payload: { error: msg },
    });
  }
};

const getDetails = (
  documentId: string,
  viewMetadata: boolean
): MasterReferenceThunkAction => async dispatch => {
  dispatch({
    type: MasterReferenceConstants.GET_MASTER_REF_DETAIL_REQUEST,
    payload: { documentId },
  });

  try {
    const { status, payload } = await MasterReferenceServices.getDetails(
      documentId
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    const { data } = payload;
    const {
      owner,
      effectiveEndDate,
      effectiveStartDate,
      originalFileName,
      referenceList,
      serviceName,
    } = data.metadata;

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REF_DETAIL_SUCCESS,
      payload: {
        document: {
          id: documentId,
          serviceName,
          owner,
          createdDate: data.createdDate,
          effectiveEndDate,
          effectiveStartDate,
          lastModifiedDate: data.lastModifiedDate,
          originalFileName,
          referenceList: viewMetadata ? referenceList : [],
          downStreamEngines: null,
          referenceRanges: [],
        },
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.GET_MASTER_REF_DETAIL_FAILURE,
      payload: { error: msg },
    });
  }
};

const syncData = (
  data: DTO.SyncDataRequest
): MasterReferenceThunkAction<string | null> => async dispatch => {
  try {
    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_REQUEST,
    });

    const { status, payload } = await MasterReferenceServices.syncData(data);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_SUCCESS,
      payload: { version: data.version },
    });

    payload.message && dispatch(AlertActions.success(payload.message));

    return payload.data;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_FAILURE,
      payload: { error: msg },
    });

    return null;
  }
};

const selectDoc = (document: DTO.MasterReferenceDetail) => ({
  type: MasterReferenceConstants.SELECT_MASTER_REF,
  payload: { document },
});

const resetUpload = () => ({
  type: MasterReferenceConstants.MASTER_REF_UPLOAD_RESET,
});

const resetSync = () => ({
  type: MasterReferenceConstants.MASTER_REF_SYNC_RESET,
});

const getDownStreamEngines = (
  documentId: string
): MasterReferenceThunkAction => async dispatch => {
  dispatch({
    type: MasterReferenceConstants.GET_DOWNSTREAM_ENGINE_REQUEST,
    payload: { documentId },
  });

  try {
    const {
      status,
      payload,
    } = await MasterReferenceServices.getDownStreamEngines(documentId);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.GET_DOWNSTREAM_ENGINE_SUCCESS,
      payload: {
        documentId,
        downStreamEngines: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.GET_DOWNSTREAM_ENGINE_FAILURE,
      payload: { error: msg },
    });
  }
};

const syncAllDownStreamEngines = (
  documentId: string
): MasterReferenceThunkAction => async dispatch => {
  dispatch({
    type: MasterReferenceConstants.MASTER_REF_SYNC_ALL_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await MasterReferenceServices.syncAllDownStreamEngines(documentId);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_ALL_SUCCESS,
    });
    dispatch(AlertActions.success('master-ref-sync-all-downstream-engines'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.MASTER_REF_SYNC_ALL_FAILURE,
      payload: { error: msg },
    });
  }
};

const readReferenceRange = (
  documentId: string,
  referenceRange: string
): MasterReferenceThunkAction => async dispatch => {
  dispatch({
    type: MasterReferenceConstants.READ_MASTER_REFERENCE_RANGE_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await MasterReferenceServices.readReferenceRange(
      documentId,
      referenceRange
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.READ_MASTER_REFERENCE_RANGE_SUCCESS,
      payload: {
        referenceRangeValue: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.READ_MASTER_REFERENCE_RANGE_FAILURE,
      payload: { error: msg },
    });
  }
};

const writeReferenceRange = (
  documentId: string,
  referenceRange: string,
  value: object | string
): MasterReferenceThunkAction => async dispatch => {
  dispatch({
    type: MasterReferenceConstants.WRITE_MASTER_REFERENCE_RANGE_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await MasterReferenceServices.writeReferenceRange(
      documentId,
      referenceRange,
      value
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: MasterReferenceConstants.WRITE_MASTER_REFERENCE_RANGE_SUCCESS,
    });
    dispatch(
      AlertActions.success('master-ref.reference.range.success.message')
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: MasterReferenceConstants.WRITE_MASTER_REFERENCE_RANGE_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearReferenceRange = (): MasterReferenceAction => ({
  type: MasterReferenceConstants.CLEAR_MASTER_REFERENCE_RANGE,
});

export const MasterReferenceActions = {
  selectDoc,
  getMasterReferences,
  upload,
  downloadReferenceFile,
  deleteDocument,
  getDetails,
  syncData,
  resetUpload,
  getMasterAPIDefinition,
  downloadAPIDefFile,
  deleteAPIDefDocument,
  getDownStreamEngines,
  resetSync,
  syncAllDownStreamEngines,
  readReferenceRange,
  writeReferenceRange,
  clearReferenceRange,
};
