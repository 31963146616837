import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import queryString from 'query-string';

const TOKEN_TYPE = 'KEYCLOAK'; // process.env.REACT_APP_TOKEN_TYPE;
const KEYCLOAK_DOMAIN = process.env.REACT_APP_KEYCLOAK_DOMAIN;
const KEY_CLOCK_REDIRECT_URL = process.env.REACT_APP_KEYCLOAK_REDIRECT_URL;
const KEYCLOAK_DEFAULT_TENANT = process.env.REACT_APP_KEYCLOAK_DEFAULT_TENANT;

interface PrivateRouteProps extends RouteProps {
  loggedIn: boolean;
  isLoggingOut?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  render,
  loggedIn,
  isLoggingOut,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (loggedIn) {
        if (render) {
          return render(props);
        }

        if (Component) {
          return <Component {...props} />;
        }

        return <Redirect to="/" />;
      }

      let redirectUrl;
      let tenant;
      if (window.location && window.location.search) {
        const qss = queryString.parse(window.location.search);
        redirectUrl = qss.return_url;
        tenant = qss.tenant as string;
        tenant && localStorage.setItem('Tenant', tenant);
      }

      let keyCloakDomain = KEYCLOAK_DOMAIN;
      if (tenant) {
        keyCloakDomain = keyCloakDomain?.replace('{tenant}', tenant);
      } else {
        keyCloakDomain = keyCloakDomain?.replace(
          '{tenant}',
          KEYCLOAK_DEFAULT_TENANT ?? ''
        );
      }

      if (!isLoggingOut && TOKEN_TYPE?.toUpperCase() === 'KEYCLOAK') {
        window.location.href = `${keyCloakDomain}${encodeURIComponent(
          `${window.location.origin}${KEY_CLOCK_REDIRECT_URL}?return_url=${(
            redirectUrl ??
            window.location.href.replace(window.location.origin, '')
          ).replace(/ /g, '@!@')}`
        )}`;
        return null;
      }

      return (
        <Redirect
          to={`/login/?${
            tenant ? `tenant=${tenant}&` : ''
          }return_url=${encodeURIComponent(
            window.location.href.replace(window.location.origin, '')
          )}`}
        />
      );
    }}
  />
);

export { PrivateRoute };
