import { addEngine } from './addengine.reducer';
import { addDocument } from './adddocument.reducer';
import { productDocs } from './productDocs.reducer';
import { language } from './language.reducer';
import { auth } from './auth.reducer';
import { resetPassword } from './resetPassword.reducer';
import { productsState as products } from './products.reducer';
import { theme } from './theme.reducer';
import { userState as user } from './user.reducer';
import { userManager } from './userManager.reducer';
import { productEngines } from './productEngines.reducer';
import { engineViewLogs } from './engineViewLogs.reducer';
import { apiTester } from './apiTester.reducer';
import { formBuilder } from './formBuilder.reducer';
import { regressionTest } from './regressionTest.reducer';
import { fileManager } from './fileManager.reducer';
import { addFile } from './addFile.reducer';
import { shareProducts } from './shareProducts.reducer';
import { productChanges } from './productChanges.reducer';
import { masterReferences } from './masterReferences.reducer';
import { system } from './system.reducer';
import { performanceMetrics } from './performanceMetrics.reducer';
import { tutorial } from './tutorial.reducer';
import { productsBoard } from './productsBoard.reducer';
import { clientManager } from './clientManager.reducer';
import { productRoadMap } from './productRoadMap.reducer';
import { alertMessage } from './alert.reducers';
import { permissionsReducer } from './permissions.reducer';
import { anonymousUser } from './anonymousUser.reducer';
import { backgroundJobsReducer } from './backgroundJobs.reducer';
import { dmlFile } from './dmlFile.reducer';
import { dataDictionaryReducer } from './dataDictionary.reducer';

const rootReducer = {
  auth,
  user,
  language,
  theme,
  addEngine,
  resetPassword,
  userManager,
  products,
  shareProducts,
  productEngines,
  engineViewLogs,
  apiTester,
  formBuilder,
  regressionTest,
  addDocument,
  productDocs,
  productChanges,
  fileManager,
  addFile,
  references: masterReferences,
  system,
  performanceMetrics,
  tutorial,
  productsBoard,
  clientManager,
  productRoadMap,
  alertMessage,
  permissions: permissionsReducer,
  anonymousUser,
  backgroundJobs: backgroundJobsReducer,
  dmlFile,
  dataDictionaryReducer,
};

export default rootReducer;
